/**
 *  Personal Site: My humble personal homepage, made with a tiny bit but not much care.
 *  <https://github.com/MattIPv4/Personal-Site/>
 *  Copyright (C) 2023 Matt Cowley (MattIPv4) (me@mattcowley.co.uk)
 *
 *  This program is free software: you can redistribute it and/or modify
 *   it under the terms of the GNU General Public License as published
 *   by the Free Software Foundation, either version 3 of the License, or
 *   (at your option) any later version.
 *  This program is distributed in the hope that it will be useful,
 *   but WITHOUT ANY WARRANTY; without even the implied warranty of
 *   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *   GNU General Public License for more details.
 *  You should have received a copy of the GNU General Public License
 *   along with this program. If not, please see
 *   <https://github.com/MattIPv4/Personal-Site/blob/master/LICENSE.md> or <http://www.gnu.org/licenses/>.
 */

$green: rgb(20, 253, 206);
$blue: rgb(129, 243, 253);
$light: rgb(239, 253, 255);

html,
body {
  background: #000;
}

@mixin blue-heading {
  color: rgba($blue, 1);
  letter-spacing: -.01rem;
  text-shadow: 0 .1rem .5rem rgba($blue, 1);

  &::selection {
    background: rgba($blue, 1);
    color: #fff;
  }
}

body {
  color: rgba($green, 1);
  font-size: 18px;
  line-height: 1.2;
  text-shadow: 0 .1rem .5rem rgba($green, .75);

  &::before,
  &::after {
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    pointer-events: none;
  }

  &::before {
    animation: crt-bg 900ms infinite;
    background: $blue;

    @keyframes crt-bg {
      0% {
        opacity: .1 * 1;
      }

      20% {
        opacity: .1 * .9;
      }

      40% {
        opacity: .1 * 1;
      }

      60% {
        opacity: .1 * .95;
      }

      80% {
        opacity: .1 * .9;
      }

      100% {
        opacity: .1 * 1;
      }
    }
  }

  &::after {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0) 50%,
      rgba(0, 0, 0, .3) 70%,
      rgba(255, 255, 255, .75)
    );
    background-size: 100% .5rem;
    opacity: .05;
  }

  .content {
    h1,
    h2,
    h3,
    ul li a {
      animation: crt-output 10ms infinite;

      @keyframes crt-output {
        0% {
          opacity: .95;
        }

        100% {
          opacity: 1;
        }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      text-transform: uppercase;
    }

    h1 {
      color: rgba($blue, 1);
      font-size: 4em;
      letter-spacing: .1em;
      text-shadow: 0 .1rem 1rem rgba($blue, .75);

      @media only screen and (max-width: 768px) {
        font-size: 2.5em;
      }

      &::selection,
      *::selection {
        background: rgba($blue, 1);
        color: #fff;
      }

      &::before {
        content: "👋 Hi, I'm ";
      }

      &::after {
        animation: blink 1.2s linear infinite;
        content: "_";

        @keyframes blink {
          0% {
            opacity: 0;
          }

          50% {
            opacity: 0;
          }

          50.1% {
            opacity: 1;
          }

          100% {
            opacity: 1;
          }
        }
      }
    }

    h2,
    h3 {
      @include blue-heading;
    }

    h2 {
      opacity: .8;
      font-size: 2.25rem;
    }

    h3 {
      opacity: .7;
      font-size: 2rem;
    }

    h4,
    h5 {
      color: rgba($light, 1);
      font-size: 1.4em;
      letter-spacing: 0;
      text-shadow: 0 .1rem 1rem rgba($light, .95);

      &::selection {
        background: rgba($light, 1);
        color: #111;
      }

      &::before {
        content: "(";
        padding-right: .1em;
      }

      &::after {
        content: ")";
        padding-left: .1em;
      }
    }

    h4,
    h3.email {
      margin-top: 45px;
    }

    a {
      &,
      &:visited {
        color: rgba($light, 1);
        text-shadow: 0 .1rem 1rem rgba($light, .95);
      }

      &:hover,
      &:focus {
        color: rgba($blue, 1);
      }

      &::selection {
        background: rgba($light, 1);
        color: #111;
      }
    }

    .items {
      &.hidden-links {
        a {
          &,
          &:visited {
            @include blue-heading;
          }
        }
      }
    }

    ul {
      display: flex;
      flex-flow: row wrap;

      li {
        flex-grow: 0;
        margin-top: .2em;

        + li {
          margin-left: .3em;
        }

        a {
          letter-spacing: 0;
        }
      }
    }

    footer {
      p {
        span {
          background: rgba($blue, 1);
          color: #fff;
          margin: -2px;
          padding: 2px;
        }
      }
    }
  }
}
